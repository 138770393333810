import { navigate } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import VideoPlayer from '../components/VideoPlayer';
import Window from '../components/Window';
import useCloseOnEsc from '../hooks/useCloseOnEsc';
import slugify from '../utils/slugify';

interface Props {
    pageContext: {
        data: {
            video_url: string;
            video_title: string;
            video_slug: string;
            showBackgroundGradient?: boolean;
            showBackgroundTriangle?: boolean;
        };
    };
    location: Location;
}

const Video: FC<Props> = ({ pageContext: { data }, location }) => {
    const {
        video_url,
        video_title,
        video_slug,
        showBackgroundGradient,
        showBackgroundTriangle,
    } = data ?? {};

    useCloseOnEsc({
        backToPreviousPage: true,
    });

    const handleNavigation = () => {
        navigate(location.pathname.replace(slugify(`/${video_slug}`), ''));
    };

    return (
        <Layout
            showBackgroundGradient={showBackgroundGradient}
            showBackgroundTriangle={showBackgroundTriangle}
        >
            <VideoWindow windowTitle={video_title} onClose={handleNavigation}>
                <Content>
                    <VideoPlayer url={video_url} />
                </Content>
            </VideoWindow>
        </Layout>
    );
};

const VideoWindow = styled(Window)`
    margin-top: 226px;
    width: 100vw;
    border-bottom: ${({ theme }) => theme.border};
    height: 100%;

    @media (min-width: ${({ theme }) => theme.mediaQueries.small}) {
        border: ${({ theme }) => theme.border};
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        margin-top: 182px;
        width: 734px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.large}) {
        margin-top: 120px;
    }

    @media (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        margin-top: 80px;
    }
`;

const Content = styled.div`
    @media (min-width: ${({ theme }) => theme.mediaQueries.medium}) {
        padding: 56px 37px 46px;
    }
`;

export default Video;
