import { navigate } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
import { useEffect } from 'react';

interface Options {
    isOpen?: boolean;
    onClose?: () => void;
    modal?: boolean;
    backToPreviousPage?: boolean;
}

const useCloseOnEsc = (options?: Options): void => {
    const { locale } = useIntl();
    const { modal = false, isOpen, onClose, backToPreviousPage } =
        options ?? {};

    useEffect(() => {
        isOpen
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'visible');

        const onKeyDown = (event: KeyboardEvent) => {
            if (modal && onClose) {
                if (event.key === 'Escape' && isOpen) {
                    onClose();
                }
            }
            if (!modal) {
                if (event.key === 'Escape') {
                    backToPreviousPage ? navigate(-1) : navigate(`/${locale}`);
                }
            }
        };

        document.addEventListener('keydown', onKeyDown, false);

        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
        };
    }, [isOpen, onClose, locale, modal, backToPreviousPage]);
};

export default useCloseOnEsc;
